<template>
  <div style="height: 100vh;">
    <q-dialog v-model="confirm" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>Apakah Anda akan keluar dari halaman?</q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn
            dense
            outline
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="confirm = false"
            label="Tidak"
          />
          <q-btn
            dense
            unelevated
            color="indigo-9"
            style="width: 80px;"
            no-caps
            @click="closeSurvey"
            label="Ya"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <div class="column no-wrap justify-between" style="height: 100%;">
      <div class="row items-center absolute-top-right q-mr-md">
        <div class="text-grey q-pr-xs">{{surveyor.nama}}</div>|
        <q-btn dense flat @click="confirm = true" color="red" label="logout" no-caps />
      </div>
      <div class="col-auto top q-pa-md q-pt-xl">
        <div>Kehadian Kajian Guru/Karyawan</div>
        <div
          style="color: grey; font-size: 0.8em;"
        >pilih status kehadian anda pada kajian di periode ini</div>
      </div>
      <div class="col-auto middle q-pa-md q-gutter-md">
        <div>
          <p class="q-mb-none">Pilih Status Kehadiran :</p>
          <div>
            <div class="column q-gutter-sm">
              <q-radio v-model="status" val="Berjalan" label="Berjalan" />
              <q-radio v-model="status" val="Sakit" label="Sakit" />
              <q-radio v-model="status" val="Izin" label="Izin" />
              <q-radio v-model="status" val="Alfa" label="Alfa" />
              <q-radio v-model="status" val="Tidak Berjalan" label="Tidak Berjalan" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 bottom row items-end">
        <div class="row" style="width: 100%;">
          <q-btn
            class="col-12 q-pa-sm no-border-radius"
            label="Submit"
            color="indigo-10"
            @click="onSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'App',

  data () {
    return {
      confirm: false,
      surveyor: {},
      step: 1,
      status:'Berjalan',
      survey:{}
    }
  },
  mounted(){
    this.$http.get('/kode_unik', {})
      .then (result => {
        if(localStorage.getItem('kode_unik') != result.data.kode_unik){
          this.$router.push('/')
        }
      })
    this.$http.get('/guru_by_noinduk/' + localStorage.getItem('surveyor'), {})
      .then (result => {
          this.surveyor =  result.data
      })
  },
  methods:{
    closeSurvey(){
      localStorage.removeItem('kode_unik')
      localStorage.removeItem('surveyor')
      this.$router.push('/')
    }, 
    async onSubmit(){
      this.survey=this.$SURVEY

      this.step = 2
      let val = (this.survey.Q1?10:0) 
                + (this.survey.Q2?10:0) 
                + (this.survey.Q3?10:0) 
                + (this.survey.Q4?10:0) 
                + (this.survey.Q5?50:0) 
                + (this.survey.Q6?50:0) 
                + (this.survey.Q7?50:0) 
                + (this.survey.Q8?10:0) 
      var data = {
        gedung: this.surveyor.gedung,
        no_kamar: this.surveyor.no_kamar,
        no_induk: this.surveyor.no_induk,
        Q1: this.survey.Q1?'Y':'N',
        Q2: this.survey.Q2?'Y':'N',
        Q3: this.survey.Q3?'Y':'N',
        Q4: this.survey.Q4?'Y':'N',
        Q5: this.survey.Q5?'Y':'N',
        Q5: this.survey.Q6?'Y':'N',
        Q5: this.survey.Q7?'Y':'N',
        Q5: this.survey.Q8?'Y':'N',
        nilai_survey: val,
        surveyor: this.surveyor.nama
      }
      if(val > 40){
        data.karantina = 'Y'
        data.tgl_karantina = moment().format('YYYY-MM-DD')
      }

      var data_kajian = {
        no_induk: this.surveyor.no_induk,
        surveyor: this.surveyor.nama,
        status_kehadiran : this.status
      }

      this.$http.post('/new_survey/', data, {})
        .then (result => {
        
        })
      
      await this.$http.post('/new_kajian/', data_kajian)
      .then (result => {
      })
      this.$router.push('/hasil-survey-kajian')
    },

  },
  computed:{
    
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Montserrat";
  src: url("~@/assets/fonts/Montserrat-Medium.otf") format("truetype");
}

* {
  font-family: "Montserrat";
}

.top {
  // min-height: 100px;
  // padding: 5px 18px;
  // overflow-y: auto;
}
.middle {
  background-color: rgb(230, 230, 230);
  // padding: 10px;
  overflow: scroll;
  flex-grow: 99;
}
.bottom {
  // height: 100px;
}

.custom-area {
  width: 90%;
  height: 220px;
  border-radius: 3px;
  padding: 8px;
}

.custom-info pre {
  width: 180px;
  font-size: 12px;
}
</style>